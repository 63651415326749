import GoogleLogin, {GoogleLogout} from 'react-google-login';


const responseGoogle = (response) => {
    console.log("response", response)
    if (response.tokenId) {
        fetch(process.env.REACT_APP_BASE_URL + '/auth?token=' + response.tokenId, {
            credentials: 'include',
        })
            .then((response) => {
                return response.json();
            })
            .then((myJson) => {
                alert(myJson)
            });
    }
}

const temp = () => {
    fetch(process.env.REACT_APP_BASE_URL, {
        credentials: 'include'
    })
        .then((response) => {
            return response.json();
        })
        .then((myJson) => {
            alert(myJson)
        });
}
    const onSuccess = () => {
      console.log('Logout made successfully');
      alert('Logout made successfully ✌');
    };


export function Loggfg() {
    return (
        <div className="App">
            <GoogleLogin
                clientId={process.env.REACT_APP_CLIENT_ID}
                buttonText="Google Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
            />
            <div>

            <GoogleLogout
                clientId={process.env.REACT_APP_CLIENT_ID}
                buttonText="Logout"
                onLogoutSuccess={onSuccess}
            ></GoogleLogout>
            </div>
            <br />
            <button onClick={temp}> Check session </button>
        </div>
    );
}