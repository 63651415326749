import React, { useState, useEffect, useRef } from "react";
import { Get, Delete, Update, Post } from "../../BaseService";
import { Modal } from "../testModal";
import { Input } from "../../comp";



export default function Pension() {

  const BASE_URL = process.env.REACT_APP_BASE_URL;  //global variable
  const [pension, setPension] = useState([]);
  const [saveToCreate, satSaveToCreate] = useState([]); //sat and names
  const [res, setRes ] = useState([])  // res not used

  const [searchInput, setSearchInput] = useState("");
  const [modalShown, setModalShown] = useState({ delete: false, edit: false, add: false, create:false});
  const [idToDelete, setIdToDelete] = useState([]);
  const [dataToEdit, setDataToEdit] = useState([]);

  const save = useRef(""); //name

  useEffect(() => {
    Get(BASE_URL + "/pension", setPension)

  }, []);

  
  useEffect(() => {
    save.current = pension
    // console.log(2)
  }, []);


  const saveForUpdate = (e) => {
    setDataToEdit({
      ...dataToEdit, [e.target.name]: e.target.value
    })
  }
  const sendUpdate = (id)=>{
    // console.log(dataToEdit)
    Update(BASE_URL + "/pension", id, dataToEdit, setRes) 
  }
  
  const saveForCreate = (e) => {
    satSaveToCreate({
      ...saveToCreate, [e.target.name]: e.target.value
    })
  }
  const sendCreate = ()=>{
    console.log(saveToCreate)
    Post(BASE_URL + "/pension", saveToCreate, setRes )
  }

  const deleteItem = (id) => {
    console.log(id)
    Delete(BASE_URL + "/pension", id)
    setPension((current) =>
    current.filter((pension) => pension.id !== id)
    );
  };
  
  const handleChange = (e) => {
    console.log(e.keyCode)
    setSearchInput(e.target.value)
    if (e.keyCode === 13) {
      // console.log(e.keyCode)
      handleSubmit()
    };
  };

  
  const handleSubmit = () => {
    if (!searchInput.length){
      // console.log(save.current)
      return setPension(save.current)}
    else if (searchInput.length > 0) { //else if why
      const afterFilter = pension.filter((states) => {
        return states.name.match(searchInput)
      })
      setPension(afterFilter)
    }
  }


  if (pension.errorMessage) {
    return <div>{pension.errorMessage}</div>
  }


  return (
    <div className="table-wrapper">
      <input
        type="search"
        placeholder="Search here"
        onChange={handleChange} // need to check nif needed here
        onKeyDown={(e) => handleChange(e)}
      />
      
      <button onClick={() => { setModalShown({create:true, delete: false, edit: false, add: false }); }}>
        craete
      </button>
      <Modal shown={modalShown.create} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { sendCreate(); setModalShown({ delete: false, edit: false, add: false, create:false }) }}> 
        <div>category:<Input  onChange={saveForCreate} name={"category"} />
        company:<Input  onChange={saveForCreate} name={"cumpeny"} />
        name:<Input onChange={saveForCreate} name={"name"} />
        kosher:<Input  onChange={saveForCreate} name={"kosher"} />
        number_id:<Input onChange={saveForCreate} name={"number_id"} />
        Exposure_to_shares:<Input onChange={saveForCreate} name={"Exposure_to_shares"} />
        </div>      </Modal>
      {(
        <table id='employee'>
          <tbody>
            <tr>
              <th>category</th>
              <th>company</th>
              <th>name</th>
              <th>kosher</th>
              <th>number_id</th>
              <th>Exposure_to_shares</th>
              <th>yield_url</th>
              <th>remarks</th>
              <th>delete</th>
              <th>add</th>
              <th>edit</th>

            </tr>
            {pension.map((item, i) => (
              <tr key={i}>
                <td>{item.category}</td>
                <td>{item.cumpeny}</td>
                <td>{item.name}</td>
                <td>{item.kosher}</td>
                <td>{item.number_id}</td>
                <td>{item.Exposure_to_shares}</td>
                <td><a className="button-20" href={item.yield_url}>url</a></td>
                <td>{item.remarks}</td>
                <td>
                  <button onClick={() => { setModalShown({create:false, delete: true, add: false, edit: false }); setIdToDelete(item.id) }}>
                    delete
                  </button>
                  <Modal shown={modalShown.delete} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { deleteItem(idToDelete); setModalShown({ create:false, delete: false, edit: false, add: false }) }}
                    children={<div>
                      are you want delete the
                      <h4>{idToDelete}</h4>
                    </div>}>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ create:false, delete: false, add: true, edit: true }); setDataToEdit(item) }}>
                    add
                  </button>
                  <Modal shown={modalShown.add} close={() => { setModalShown({create:false,  delete: false, add: false, edit: false }); }} 
                    children={<div>id: <h4>{dataToEdit.id}</h4>
                      category:<h4>{dataToEdit.category}</h4>
                      cumpeny:<h4>{dataToEdit.cumpeny}</h4>
                      name:<h4>{dataToEdit.name}</h4>
                      kosher:<h4>{dataToEdit.kosher}</h4>
                      number_id:<h4>{dataToEdit.number_id}</h4>
                      Exposure_to_shares:<h4>{dataToEdit.Exposure_to_shares}</h4></div>}>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ delete: false, edit: true, add: false }); setDataToEdit(item) }}>
                    edit
                  </button>
                  <Modal shown={modalShown.edit} close={() => { setModalShown({ delete: false, add: false, edit: false }); }} fun={() => { sendUpdate(item.id); setModalShown({ delete: false, edit: false, add: false }) }}>
                    id:<Input value={dataToEdit.id} onChange={saveForUpdate} name={"id"} type={"text"} disabled={true} />
                    category:<Input value={dataToEdit.category} onChange={saveForUpdate} name={"category"} />
                    company:<Input value={dataToEdit.cumpeny} onChange={saveForUpdate} name={"cumpeny"} />
                    name:<Input value={dataToEdit.name} onChange={saveForUpdate} name={"name"} />
                    kosher:<Input value={dataToEdit.kosher} onChange={saveForUpdate} name={"kosher"} />
                    number_id:<Input value={dataToEdit.number_id} onChange={saveForUpdate} name={"number_id"} />
                    Exposure_to_shares:<Input value={dataToEdit.Exposure_to_shares} onChange={saveForUpdate} name={"Exposure_to_shares"} />
                  </Modal>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}





