import React, {useState} from "react";
import { Post } from "../../BaseService";
import { Input } from "../../comp";


export default function InputPosts() {
    const [post, setPost] = useState([])
    const BASE_URL = process.env.REACT_APP_BASE_URL;

  
    const handelChange=(e)=>{
        setPost({
            ...post, [e.target.name]: e.target.value
        })
        console.log(post)
    }
    const onSubmit = ()=>{
        if (!post.image) {
            return alert("A image field is empty.");}
        if (!post.title ) {
            return alert("A title field is empty.")};
        if (!post.text ) {
        return alert("A text field is empty.")};
            
    Post(BASE_URL+"/post", post)
}

    return(
        <div>
     <h2>העלאת פוסטים</h2>
    <div className="form-group">
            העלאת תמונה
      <input className="form-control" type="text"  onChange={(e)=>{handelChange(e)}} name="image" />
    </div>
    <div className="form-group">
            כותרת
      <input className="form-control" type={"text"}  onChange={(e)=>{handelChange(e)}} name={"title"} />
    </div>
    <div className="form-group">
            תוכן
      <textarea className="textarea" placeholder="Hello there" onChange={(e)=>{handelChange(e)}} name="text"></textarea>
    </div>
    <button onClick={onSubmit}>submit</button>

        </div>
    )
    
}