import React, { useState, useEffect } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pension from "./pension";
import Debentures from "./debentures";
import MutualFunds from "./mutualFunds";
import Instructions from "./_instructions";
import Table from "./_table";
import Table1 from "../BaseTable";
// import Table from "./table";


export default function Tabs_bootstrap() {
  const [key, setKey] = useState("home");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="home" title="פנסיה">
        <Table1 url={"https://62f0041857311485d12c245d.mockapi.io/api/v1/pension"} columns={["id","category", "cumpeny", "name", "kosher", "number_id", "Exposure_to_shares", "yield_url", "remarks"]}/>
      </Tab>
      <Tab eventKey="profile" title="אגרות חוב">
        {/* <Table1 url={"/debentures"} columns={["company","name", "number"]}  /> */}
        
        {/* <Debentures/> */}
      </Tab>
      <Tab eventKey="contact" title="קרנות סל ונאמנות כשרות">
        {/* < Table1 url={"/mutualFunds"} columns={["type", "campeny", "number", "name", "kosher1", "kosher2", "url_maya"]} /> */}

        {/* <MutualFunds/>  */}
      </Tab>
      <Tab eventKey="contact1" title="הוראות למשקיע הפרטי">
        {/* <Table1 url={"/instructions"} columns={["product",  "glat_hon","fainshtein", "shafran", "vais", "elyashiv"  ]} /> */}
        {/* <Instructions/>  */}
      </Tab>
      <Tab eventKey="contact2" title="contact">
22222
      </Tab>

    </Tabs>
  );
}
