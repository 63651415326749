import React, {useState, useEffect} from "react";
import { GetById } from "../../BaseService";
import { useParams } from "react-router-dom";




export default function Posts  ()  {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [page, setPage] = useState([]);
  const { id } = useParams();  
  
  useEffect(() => {
    GetById( BASE_URL+"/posts",id, setPage);
  }, []);



  return (
    
      <div >

    <p>lorem*20</p>

          <h1 className="heading">{page[0].title}</h1><img className="image" src={ page[0].image} alt="post" /><p className="post">{page[0].text}</p>
        </div>    
  );
};
  
