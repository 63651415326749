import React from "react";
import logo from './logo.jpg'

export default function About() {

  return (
    <div style={{background: "yellow"}}>
        
         <img className="img" src={logo} alt="Logo" />;

        <h3>יעוץ להשקעות</h3>



    </div>
 
  );
}