import React, { useState } from "react";
import { Post } from "../BaseService";
import { Link } from "react-router-dom";
// import {ModalDelete, Input} from "../comp"
import { ModalData } from "./props";


export default function HomePage() {
  const [savings, setSavings] = useState({});
  const [response, setResponse] = useState({});

  const urlActor = "http://localhost:8000/gallery";


  const handleSubmit = (e) => {
    setSavings({ ...savings, [e.target.name]: e.target.value });
    console.log(savings);
  };
  const send = ()=>{
  Post(urlActor, savings, setResponse);
  console.log(response)
  }


  return (
    
    <div>
      <h1>חישוב הפקדות</h1>
      money
      <div className="form-group">
      <input className="form-control" name="money" onChange={(e) => handleSubmit(e)} type="number" />
      </div>
      years
      <div className="form-group">
      <input className="form-control" name="years" onChange={(e) => handleSubmit(e)} type="number" />
      </div>        
      ManagementFee
      <div className="form-group">
      <input className="form-control" name="ManagementFee" onChange={(e) => handleSubmit(e)} type="number" />
      </div>
      percent
      <div className="form-group">
      <input className="form-control" name="percent" onChange={(e) => handleSubmit(e)} type="number" />
      </div>
      Onetime (optional)
      <div className="form-group">
      <input className="form-control" name="Onetime" onChange={(e) => handleSubmit(e)} type="number" />
      </div>
      <div className="button">
      {/* <Link className="button-19"  onClick={send}>send</Link> */}
          {" "}
      </div>

    <ModalData response={response} send = {send} />
      
   {/* <ModalDelete confirmFunc={resp}/> */}


    {/* <ModalDelete/> */}

    </div>

  );
}
