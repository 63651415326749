import React from 'react';

import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from './refreshToken.js';

const clientId = process.env.REACT_APP_CLIENT_ID;


const responseGoogle = (response) => {
    if (response.tokenId){
      fetch('http://localhost:8000/auth?token='+ response.tokenId,{
        credentials: 'include',
        // To cause browsers to send a request with credentials included on both same-origin and cross-origin calls, 
        // add credentials: 'include' to the init object you pass to the fetch() method.
       })
      .then((response) => {
        return response.json();
      })
      .then((myJson) => {
        alert(myJson)
      });
    }
}
  
const temp = () =>{
  fetch('http://localhost:8000',{
    credentials:'include' 
  })
  .then((response) => {
    return response.json();
  })
  .then((myJson) => {
    alert(myJson)
  });
}
  

function Login() {
//   const onSuccess = (res) => {
//     console.log('Login Success: currentUser:', res.profileObj);
//     alert(
//       `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
//     );
//     refreshTokenSetup(res);
//   };

//   const onFailure = (res) => {
//     console.log('Login failed: res:', res);
//     alert(
//       `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
//     );
//   };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        isSignedIn={true}
      />
    <button onClick={temp}> Check session </button>

    </div>
  );
}

export default Login;