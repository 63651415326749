import React, { useState, useEffect, useRef } from "react";
import { Get, Delete, Update, Post } from "../../BaseService";
import { Modal } from "../testModal";
import { Input } from "../../comp";



export default function MutualFunds() {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [mutualFunds, setMutualFunds] = useState([]);
  const [saveToCreate, satSaveToCreate] = useState([]);
  const [res, setRes ] = useState([])

  const [searchInput, setSearchInput] = useState("");
  const [modalShown, setModalShown] = useState({ delete: false, edit: false, add: false, create:false});
  const [idToDelete, setIdToDelete] = useState([]);
  const [dataToEdit, setDataToEdit] = useState([]);

  const save = useRef("");

  useEffect(() => {
    Get(BASE_URL + "/mutualFunds", setMutualFunds)

  }, []);

  
  useEffect(() => {
    save.current = mutualFunds
    console.log(2)
  }, []);


  const saveForUpdate = (e) => {
    setDataToEdit({
      ...dataToEdit, [e.target.name]: e.target.value
    })
  }
  const sendUpdate = (id)=>{
    console.log(dataToEdit)
    Update(BASE_URL + "/mutualFunds", id, dataToEdit, setRes) 
  }
  
  const saveForCreate = (e) => {
    satSaveToCreate({
      ...saveToCreate, [e.target.name]: e.target.value
    })
  }
  const sendCreate = ()=>{
    console.log(saveToCreate)
    Post(BASE_URL + "/mutualFunds", saveToCreate, setRes )
  }

  const deleteItem = (id) => {
    console.log(id)
    Delete(BASE_URL + "/mutualFunds", id)
    
    setMutualFunds((current) =>
    current.filter((mutualFunds) => mutualFunds.id !== id)
    );
  };
  
  const handleChange = (e) => {
    console.log(e.keyCode)
    setSearchInput(e.target.value)
    if (e.keyCode === 13) {
      console.log(e.keyCode)
      handleSubmit()
    };
  };

  
  const handleSubmit = () => {
    if (!searchInput.length){
      console.log(save.current)
      return setMutualFunds(save.current)}
      else if (searchInput.length > 0) {
        const afterFilter = mutualFunds.filter((states) => {
          return states.name.match(searchInput)
        })
        setMutualFunds(afterFilter)
        console.log(afterFilter, mutualFunds)
    }
  }
  if (mutualFunds.errorMessage) {
    return <div>{mutualFunds.errorMessage}</div>
  }



  return (
    <div className="table-wrapper">
      <input
        type="search"
        placeholder="Search here"
        onChange={handleChange}
        onKeyDown={(e) => handleChange(e)}
      />
      
      <button onClick={() => { setModalShown({create:true, delete: false, edit: false, add: false }); }}>
        craete
      </button>
      <Modal shown={modalShown.create} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { sendCreate(); setModalShown({ delete: false, edit: false, add: false, create:false }) }}>
        <div>type:<Input  onChange={saveForCreate} name={"type"} />
        campeny:<Input  onChange={saveForCreate} name={"campeny"} />
        number:<Input onChange={saveForCreate} name={"number"} />
        name:<Input  onChange={saveForCreate} name={"name"} />
        kosher1:<Input onChange={saveForCreate} name={"kosher1"} />
        kosher2:<Input onChange={saveForCreate} name={"kosher2"} />
        </div>      </Modal>
      {(
        <table id='employee'>
          <tbody>
            <tr>
            <th>type</th>
              <th>company</th>
              <th>number</th>
              <th>name</th>
              <th>kosher1</th>
              <th>kosher2</th>
              <th>url_maya</th>
              <th>delete</th>
              <th>add</th>
              <th>edit</th>

            </tr>
            {mutualFunds.map((item, i) => (
              <tr key={i}>
                <td>{item.type}</td>
                <td>{item.campeny}</td>
                <td>{item.number}</td>
                <td>{item.name}</td>
                <td>{item.kosher1}</td>
                <td>{item.kosher2}</td>
                <td><a className="button-20" href={item.url_maya}>url</a></td>
                <td>
                  <button onClick={() => { setModalShown({create:false, delete: true, add: false, edit: false }); setIdToDelete(item.id) }}>
                    delete
                  </button>
                  <Modal shown={modalShown.delete} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { deleteItem(idToDelete); setModalShown({ create:false, delete: false, edit: false, add: false }) }}
                    children={<div>
                      are you want delete the
                      <h4>{idToDelete}</h4>
                    </div>}>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ create:false, delete: false, add: true, edit: true }); setDataToEdit(item) }}>
                    add
                  </button>
                  <Modal shown={modalShown.add} close={() => { setModalShown({create:false,  delete: false, add: false, edit: false }); }} 
                    children={<div>id: <h4>{dataToEdit.id}</h4>
                      type:<h4>{dataToEdit.type}</h4>
                      cumpeny:<h4>{dataToEdit.campeny}</h4>
                      number:<h4>{dataToEdit.number}</h4></div>}>
                      name:<h4>{dataToEdit.name}</h4>
                      kosher1:<h4>{dataToEdit.kosher1}</h4>
                      kosher2:<h4>{dataToEdit.kosher2}</h4>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ delete: false, edit: true, add: false }); setDataToEdit(item) }}>
                    edit
                  </button>
                  <Modal shown={modalShown.edit} close={() => { setModalShown({ delete: false, add: false, edit: false }); }} fun={() => { sendUpdate(item.id); setModalShown({ delete: false, edit: false, add: false }) }}>
                    id:<Input value={dataToEdit.id} onChange={saveForUpdate} name={"id"} type={"text"} disabled={true} />
                    type:<Input value={dataToEdit.type} onChange={saveForUpdate} name={"type"} />
                    cumpeny:<Input value={dataToEdit.campeny} onChange={saveForUpdate} name={"campeny"} />
                    number:<Input value={dataToEdit.number} onChange={saveForUpdate} name={"number"} />
                    name:<Input value={dataToEdit.name} onChange={saveForUpdate} name={"name"} />
                    kosher1:<Input value={dataToEdit.kosher1} onChange={saveForUpdate} name={"kosher1"} />
                    kosher2:<Input value={dataToEdit.kosher2} onChange={saveForUpdate} name={"kosher2"} />
                  </Modal>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}








