import React from "react";
import "./testModal.css"


export function Modal({ children, shown, close, fun}) {
	return shown ? (
		<div
			className="modal-backdrop"
			onClick={() => {
				close();
			}}
		>
			<div
				className="modal__container"
				 onClick={e => {
					e.stopPropagation();
				}}
			>
				<button onClick={close}>Close</button>
				<button onClick={fun}>buttonName</button>

				{children}

			</div>
		</div>
	) : null;
}



