import axios from 'axios'




export function Get(BaseUrl, data) {
  axios.get(BaseUrl)
.then(res => {
  if(!res.data) {
    data({ errorMessage: "not found" })
  }
  else{
    const persons = res.data[0];
    // const persons = res.data;
    data( persons.items );
    // data( persons );
  }
}).catch(error => {
  data({ errorMessage: error.message });
  console.error('There was an error!', error);
})
}


export const Update= async(BaseUrl, id, data, responseData) => {
  axios.put(BaseUrl + id, data)
  .then( (response)=> {
    console.log(response);
    responseData(response)
  })
  .catch((error) =>{
    console.log(error);
  });


}


export function Delete(BaseUrl, id) {
  fetch(`${BaseUrl}/${id}`, {
    method: "DELETE",
  });
}

export function Post(BaseUrl, data, responseData) {
  axios.post(BaseUrl, data)
  .then( (response)=> {
    console.log(response);
    responseData(response)
  })
  .catch((error) =>{
    console.log(error);
  });
}

export const GetById = (BaseUrl, id, data) => {
  fetch(`${BaseUrl}/${id}`)
    .then((response) => response.json())
    .then((json) => {
      data(json);
    });
};
