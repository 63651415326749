import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Input } from "../comp"
import React, { useState } from "react";

export const ModalData = ({ response, send }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <>
      <Button variant="primary" onClick={() => { handleShow(); send() }}>
        תוצאה
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>תוצאה</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
            <>
              count_of_save
              <Input value={response ? response.tax : ""} />
              count
              <Input value={response ? response.count : ""} />
              allYear
              <Input value={response ? response.allYear : ""} />
              <Input value={response ? response["((allYear + oneyear) * 0.1"] : ""} />
            </>

            {/* {()=>resp} */}
            {/* </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}


export const ModalUpdate = ({ rows, save }) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(rows)


  const dataForInput = () => {
    let arr = []
    for (const [key, value] of Object.entries(rows)) {
      arr.push(<div>{key}<input name={key} value={value} type="text" /></div>)
    }
    return arr
  }



  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        תוצאה
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>עדכן</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          {dataForInput()}
            {/* <div key={i}> */}

              {/* <input key={column} value={column} type="text" /> */}
            {/* </div> */}

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => { handleClose(); save() }}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
