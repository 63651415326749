import Login from "./Login"
import LoginHooks from "./LoginHooks"
import Logout from "./Logout"
import LogoutHooks from "./LogoutHooks"



const Loginin = () => (

    <div className="App">
        <h2>The Components way</h2>
        <Login />
        <br />
        <Logout />
        <h2>The Hooks way</h2>
        <LoginHooks />
        <LogoutHooks />
        <br />
        If it does helped you feel free to star{' '}
        <a href="https://github.com/Sivanesh-S/react-google-authentication">
            Github Repo
        </a>{' '}
        😉
    </div>
)
export default Loginin

