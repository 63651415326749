import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { Delete, Get } from "../BaseService";
import { ModalUpdate } from "./props";
import { Modal } from "./testModal";




export default function Table1({ url, columns }) {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [dataForTable, setDataForTable] = useState([]);
  const [modalShown, toggleModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // const [saveBeforeSearch, setSaveBeforeSearch] = useState([])

  const save = useRef("");
  
  
  useEffect(() => {
    // Get(BASE_URL + url, setDataForTable)
    Get(url, setDataForTable)
    save.current = dataForTable
    console.log(11)
  }, []);
  const save1 = dataForTable

console.log("save, save1",save, save1)
  useEffect(() => {
    save.current = dataForTable
    console.log(17)
  }, []);

  const handleChange = (e) => {
    setSearchInput(e.target.value)
  if (e.keyCode === 13) {
      console.log(e.keyCode)
      handleSubmit()
    };
  };

  const handleSubmit = () => {
    console.log(searchInput)
    if (!searchInput.length){
      console.log(save.current)
      return setDataForTable(save.current)

    }
    else if (searchInput.length > 0) {
      const afterFilter = dataForTable.filter((states) => {
        return states.name.match(searchInput)
      })
      setDataForTable(afterFilter)
      console.log(afterFilter, dataForTable)
    }
  }



  const print = (data, color)=>{
    console.log(`%c${data}`, `color: ${color}`);
  }

  const deleteItem = (id) => {
    console.log(id)
    Delete(BASE_URL + url, id)

    setDataForTable((current) =>
      current.filter((dataForTable) => dataForTable.id !== id)
    );
  };


  const dataForInput = (row, data) => {
    let arr = []
    for (const [key, value] of Object.entries(row)) {
      arr.push(<div>{key}<input name={key} value={value} type="text" /></div>)
      console.log(arr)
    }
    return (
      <td><button onClick={() => { toggleModal(!modalShown); }}>
        Toggle Modal
      </button>
        <Modal shown={modalShown} close={() => { toggleModal(false); }} >
          {data}
        </Modal></td>
    )
  }


  if (dataForTable.errorMessage) {
    return <div>{dataForTable.errorMessage}</div>
  }

  const tableHeaders = (<thead>
    <tr>
      <th>delete</th>
      <th>update</th>
      {columns.map((column) => {
        return <th>{column}</th>;
      })}
    </tr>
  </thead>);

  const tableBody = dataForTable.map((row) => {
    return (
      <tr>
        <td><button onClick={() => deleteItem(row.id)}>delete</button></td>
        {columns.map((column) => {
          // <td><ModalUpdate rows={row} save={update(row.id)} /></td>

          if (typeof row[column] == "string") {
            if (row[column].substr(0, 4) === "http") {
              return <td><Link to={row[column]}>url</Link></td>
            }
            else return <td>{row[column]}</td>;
          }
          else return <td>{row[column]}</td>;
        })}
      </tr>);
  });

  return(<div>
      <div className="table-wrapper">
      <input
        type="search"
        placeholder="Search here"
        onChange={handleChange}
        onKeyDown={(e) => handleChange(e)}
      />
      </div>
  
  <table id='employee'>
    {tableHeaders}
    {tableBody}
  </table>
  
  </div>
)};






