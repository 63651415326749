import React from "react";
import {  useLocation } from "react-router-dom";
// import { Input } from "../componentsToFunctions";


export default function Info() {
  const { data } = useLocation().state;
  console.log(data)



  return (
    <div>
      {data}
    </div>
  );
}
