import React, { useState, useEffect } from "react";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./components/homePage";
import Info from "./components/info";
import Posts from "./components/blogs/Posts";
import About from "./components/abuot";
import PostPage from "./components/blogs/PostPage";
import Pension from "./components/kosher/pension";
import Tabs_bootstrap from "./components/kosher/tabs";
import Debentures from "./components/kosher/debentures";
import MutualFunds from "./components/kosher/mutualFunds";
import InputPosts from "./components/blogs/inputPosts";
import GoogleAuthentication from "./components/googleLogin";
import Table1 from "./components/BaseTable";


import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Loginin from "./components/loginGithab/setupLogin";
import Apppi from "./components/loginGithab/loginPage";
import { Loggfg } from "./components/loginGithab/loginTest1";



export default function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
/*
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      alert(e);
    }
  }
  */
 return (
   <div className="App">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/login">login</Nav.Link>
              <Nav.Link href="/calculator">calculator</Nav.Link>
              <Nav.Link href="/info">info</Nav.Link>
              <Nav.Link href="/pagePost">pagePost</Nav.Link>
              <Nav.Link href="/tabs">tabs</Nav.Link>
              <NavDropdown title="Tables" id="basic-nav-dropdown">
                <NavDropdown.Item href="/pension">pension</NavDropdown.Item>
                <NavDropdown.Item href="/mutualFunds">
                  mutualFunds              </NavDropdown.Item>
                <NavDropdown.Item href="/debentures">debentures</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <BrowserRouter>
        <Routes>
        <Route path="/" element={<HomePage />}  />

          <Route path="/login" element={<GoogleAuthentication />} exact />
          <Route path="/calculator" element={<HomePage />} />
          <Route path="/info" element={<Info />} />
          <Route path="/post/:id" element={<Posts />} />
          <Route path="/about" element={<About />} />
          <Route path="/pension" element={<Pension />} />
          <Route path="/tabs" element={<Tabs_bootstrap />} />
          <Route path="/inputPosts" element={<InputPosts />} />
          <Route path="/mutualFunds" element={<MutualFunds />} />
          <Route path="/debentures" element={<Debentures />} />
          <Route path="/pagePost" element={<PostPage />} />
          <Route path="/data" element={<Table1 />} />
          <Route path="/1" element={<Loginin />} />
          <Route path="/2" element={<Apppi />} />
          <Route path="/3" element={<Loggfg />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
};

