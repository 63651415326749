/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Get } from "../../BaseService";

export default function PostPage() {
  const [page, setPage] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    Get( BASE_URL+"/posts", setPage);
  }, []);

  return (
    <div>
        <h1>פוסטים</h1>
      <div className="table-wrapper">
        {!!page.length && (
          <table className="fl-table">
            
            <tbody>
              {page.map((states, i) => (
                <tr key={i}>
                  <td>{states.id}</td>
                  <td>{states.title}</td>
                  <td><img className="img" src={states.image}></img></td>
                  <td><Link className="button-83" to={`/post/${states.id}`}>url   {states.id}</Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
