import React, { useState, useEffect, useRef } from "react";
import { Get, Delete, Update, Post } from "../../BaseService";
import { Modal } from "../testModal";
import { Input } from "../../comp";



export default function Debentures() {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [debentures, setDebentures] = useState([]);
  const [saveToCreate, satSaveToCreate] = useState([]);
  const [res, setRes ] = useState([])

  const [searchInput, setSearchInput] = useState("");
  const [modalShown, setModalShown] = useState({ delete: false, edit: false, add: false, create:false});
  const [idToDelete, setIdToDelete] = useState([]);
  const [dataToEdit, setDataToEdit] = useState([]);

  const save = useRef("");

  useEffect(() => {
    Get(BASE_URL + "/debentures", setDebentures)

  }, []);

  
  useEffect(() => {
    save.current = debentures
    console.log(2)
  }, []);


  const saveForUpdate = (e) => {
    setDataToEdit({
      ...dataToEdit, [e.target.name]: e.target.value
    })
  }
  const sendUpdate = (id)=>{
    console.log(dataToEdit)
    Update(BASE_URL + "/debentures", id, dataToEdit, setRes) 
  }
  
  const saveForCreate = (e) => {
    satSaveToCreate({
      ...saveToCreate, [e.target.name]: e.target.value
    })
  }
  const sendCreate = ()=>{
    console.log(saveToCreate)
    Post(BASE_URL + "/debentures", saveToCreate, setRes )
  }

  const deleteItem = (id) => {
    console.log(id)
    Delete(BASE_URL + "/debentures", id)
    
    setDebentures((current) =>
    current.filter((debentures) => debentures.id !== id)
    );
  };
  
  const handleChange = (e) => {
    console.log(e.keyCode)
    setSearchInput(e.target.value)
    if (e.keyCode === 13) {
      console.log(e.keyCode)
      handleSubmit()
    };
  };

  
  const handleSubmit = () => {
    if (!searchInput.length){
      console.log(save.current)
      return setDebentures(save.current)}
      else if (searchInput.length > 0) {
        const afterFilter = debentures.filter((states) => {
          return states.name.match(searchInput)
        })
        setDebentures(afterFilter)
        console.log(afterFilter, debentures)
    }
  }
  if (debentures.errorMessage) {
    return <div>{debentures.errorMessage}</div>
  }


  return (
    <div className="table-wrapper">
      <input
        type="search"
        placeholder="Search here"
        onChange={handleChange}
        onKeyDown={(e) => handleChange(e)}
      />
      
      <button onClick={() => { setModalShown({create:true, delete: false, edit: false, add: false }); }}>
        craete
      </button>
      <Modal shown={modalShown.create} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { sendCreate(); setModalShown({ delete: false, edit: false, add: false, create:false }) }}>
        <div>company:<Input  onChange={saveForCreate} name={"company"} />
        name:<Input onChange={saveForCreate} name={"name"} />
        number:<Input  onChange={saveForCreate} name={"number"} />
        </div>      </Modal>
      {(
        <table id='employee'>
          <tbody>
            <tr>
            <th>company</th>
              <th>number_id</th>
              <th>name</th>
              <th>delete</th>
              <th>add</th>
              <th>edit</th>

            </tr>
            {debentures.map((item, i) => (
              <tr key={i}>
                <td>{item.company}</td>
                <td>{item.name}</td>
                <td>{item.number}</td>
                <td>
                  <button onClick={() => { setModalShown({create:false, delete: true, add: false, edit: false }); setIdToDelete(item.id) }}>
                    delete
                  </button>
                  <Modal shown={modalShown.delete} close={() => { setModalShown({create:false, delete: false, add: false, edit: false }); }} fun={() => { deleteItem(idToDelete); setModalShown({ create:false, delete: false, edit: false, add: false }) }}
                    children={<div>
                      are you want delete the
                      <h4>{idToDelete}</h4>
                    </div>}>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ create:false, delete: false, add: true, edit: true }); setDataToEdit(item) }}>
                    add
                  </button>
                  <Modal shown={modalShown.add} close={() => { setModalShown({create:false,  delete: false, add: false, edit: false }); }} 
                    children={<div>id: <h4>{dataToEdit.id}</h4>
                      company:<h4>{dataToEdit.company}</h4>
                      name:<h4>{dataToEdit.name}</h4>
                      number_id:<h4>{dataToEdit.number}</h4></div>}>
                  </Modal>
                </td>
                <td>
                  <button onClick={() => { setModalShown({ delete: false, edit: true, add: false }); setDataToEdit(item) }}>
                    edit
                  </button>
                  <Modal shown={modalShown.edit} close={() => { setModalShown({ delete: false, add: false, edit: false }); }} fun={() => { sendUpdate(item.id); setModalShown({ delete: false, edit: false, add: false }) }}>
                    id:<Input value={dataToEdit.id} onChange={saveForUpdate} name={"id"} type={"text"} disabled={true} />
                    cumpeny:<Input value={dataToEdit.company} onChange={saveForUpdate} name={"company"} />
                    name:<Input value={dataToEdit.name} onChange={saveForUpdate} name={"name"} />
                    number_id:<Input value={dataToEdit.number} onChange={saveForUpdate} name={"number"} />
                  </Modal>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}





